import { ref, uploadString, getDownloadURL } from "firebase/storage";
import * as APIS from "../../libs/apis";
import { MEMBER, TagManager } from "./../action_const";
import { ERROR } from "../../libs/utils";
import { cooldown } from "./action_otp";
import { fbAuth, memberProfileRef } from "../../libs/Firebase";

export const createUserProfile = (data, line, callback) => {
  TagManager.event("btn-create_user_profile");
  let isFunc = typeof callback === "function";
  return (dispatch) => {
    dispatch({ type: MEMBER.IS_PROCESS, isProcess: true });
    APIS.createUserProfile(data)
      .then((res) => {
        setTimeout(() => {
          if (!res.data || !res.data.result) {
            isFunc && callback(res.data.error);
            return dispatch({
              type: MEMBER.PROFILE_REGISTER,
              isProcess: false,
              errorMessage: res.data && res.data.error,
              isError: true,
            });
          }

          let returnData = res.data;
          if (line) {
            APIS.updateProfileLineId({
              businessCode: line.businessCode,
              lineId: line.lineUserInfo.userId,
            }).then((updateRes) => {
              if (returnData.data && returnData.data[0])
                returnData.data[0].line_list = updateRes.data.data;
            });
          }

          if (data.customForm) {
            APIS.insertCustomForm({
              businessCode: data.business_code_ref,
              customFormValue: data.customForm,
              member_badge_code: returnData.data[0].member_badge_code,
            });
          }

          isFunc && callback(null, res.data.data && res.data.data[0]);
          return dispatch({
            type: MEMBER.PROFILE_REGISTER,
            isProcess: false,
            register:
              (res.data &&
                res.data.data.length > 0 &&
                res.data.data[0].is_registered) ||
              true,
            data: returnData,
          });
        }, 500);
      })
      .catch((err) => {
        isFunc && callback((err && err.message) || err);
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: { isError: true, isLoading: true },
        });
      });
  };
};

export const updateUserProfile = (data, callback) => {
  TagManager.event("btn-update_user_profile");
  return (dispatch) => {
    dispatch({ type: MEMBER.IS_PROCESS, isProcess: true });
    APIS.updateUserProfile(data)
      .then((res) => {
        setTimeout(() => {
          if (!res.data || !res.data.result) {
            return dispatch({
              type: MEMBER.PROFILE_REGISTER,
              isProcess: false,
              errorMessage: res.data && res.data.error,
              isError: true,
            });
          } else {
            let dataUser = res.data.data.length > 0 && res.data.data[0];
            dispatch({
              type: MEMBER.PROFILE_REGISTER,
              register: dataUser.is_registered || true,
              isProcess: false,
              data: res.data,
            });
            if (typeof callback === "function") callback();
          }
          dispatch({ type: MEMBER.SET_UPDATE_PROFILE_SUCCESS, data: true });
        }, 500);
      })
      .catch((err) => {
        dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: {
            isError: true,
            isLoading: true,
          },
        });
        dispatch({ type: MEMBER.SET_UPDATE_PROFILE_SUCCESS, data: false });
      });
  };
};

export const updateUserAvatar = (photoName, photoData, memberBadgeCode, callback) => {
  TagManager.event("btn-update_avatar");
  return (dispatch) => {
    dispatch({ type: MEMBER.IS_PROCESS, isProcess: true });
    uploadUserAvatar(photoName, photoData, memberBadgeCode)
      .then((data) => {
        return APIS.updateUserProfile(data);
      })
      .then(({ data }) => {
        let avatarImg = data.data;

        if (data.result)
          dispatch({ type: MEMBER.UPDATE_PROFILE_AVATAR, data: avatarImg });

        setTimeout(() => {
          if (typeof callback === "function") callback(avatarImg);
          return dispatch({ type: MEMBER.IS_PROCESS, isProcess: false });
        }, 700);
      })
      .catch((err) => {
        ERROR("updateUserAvatar catch", err);
        return dispatch({ type: MEMBER.IS_PROCESS, isProcess: false });
      });
  };
};

const uploadUserAvatar = (photoName, photoData, memberBadgeCode) => {
  TagManager.event("btn-upload_avatar");
  return new Promise(function (resolve, reject) {    
    uploadString(ref(memberProfileRef(memberBadgeCode), photoName), photoData, "data_url")
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (downloadUrl) => {
          resolve({ photoUrl: downloadUrl });
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkNewPhoneNumber = (phoneNumber, callback) => {
  TagManager.event("btn-check_new_phone_number");
  return (dispatch) => {
    APIS.checkNewPhone(phoneNumber)
      .then(({ data }) => {
        if (data.result) {
          cooldown(dispatch, new Date().getTime()); // cooldown otp
          if (typeof callback === "function") callback(null, data.data);
        } else {
          if (typeof callback === "function") callback(data.error);
        }
      })
      .catch((err) => {
        ERROR("checkNewPhoneNumber catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const checkNewPhoneNumberInter = (phoneNumber, callback) => {
  TagManager.event("btn-check_new_phone_number");
  return (dispatch) => {
    APIS.checkNewPhoneNumber(phoneNumber)
      .then(({ data }) => {
        if (data.result) {
          cooldown(dispatch, new Date().getTime()); // cooldown otp
          if (typeof callback === "function") callback(null, data.data);
        } else {
          if (typeof callback === "function") callback(data.error);
        }
      })
      .catch((err) => {
        ERROR("checkNewPhoneNumberInter catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

function changePhoneNumberFormat(countryCode, phoneNumber) {
  return String(phoneNumber).length === 10
    ? String(phoneNumber).replace(/^0/g, countryCode)
    : phoneNumber;
}

export const changeNewPhoneNumber = (
  phoneNumber,
  refCode,
  otp,
  countryCallingCode,
  token,
  countryCode,
  callback
) => {
  TagManager.event("btn-change_phone_number");
  let phoneNumberIFormat = changePhoneNumberFormat(
    countryCallingCode,
    phoneNumber
  );

  return (dispatch) => {
    APIS.changeNewPhone(phoneNumberIFormat, refCode, otp, token, countryCode)
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);
        } else {
          if (typeof callback === "function") callback(data.error);
        }
      })
      .catch((err) => {
        ERROR("changeNewPhoneNumber catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const getMemberAddress = (callback) => {
  return (dispatch) => {
    APIS.memberAddress("get")
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);
          return dispatch({ type: MEMBER.MEMBER_ADDRESS, data: data });
        } else return Promise.reject(data.error || "result error");
      })
      .catch((err) => {
        ERROR("getMemberAddress catch", err);
        if (typeof callback === "function") callback(err);
        return dispatch({ type: MEMBER.MEMBER_ADDRESS });
      });
  };
};

export const createMemberAddress = (data, callback) => {
  TagManager.event("btn-create_member_address");
  return (dispatch) => {
    APIS.memberAddress("post", data)
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);
        } else return Promise.reject(data.error || "result error");
      })
      .catch((err) => {
        ERROR("createMemberAddress catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const updateMemberAddress = (data, callback) => {
  TagManager.event("btn-update_member_address");
  return (dispatch) => {
    APIS.memberAddress("put", data)
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);
        } else return Promise.reject(data.error || "result error");
      })
      .catch((err) => {
        ERROR("updateMemberAddress catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const deleteMemberAddress = (data, callback) => {
  TagManager.event("btn-delete_member_address");
  return (dispatch) => {
    APIS.memberAddress("delete", data)
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);
        } else return Promise.reject(data.error || "result error");
      })
      .catch((err) => {
        ERROR("deleteMemberAddress catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const removeLineLiff = (data, callback) => {
  TagManager.event("btn-delete_line_liff");
  return (dispatch) => {
    APIS.removeLineLiff(data)
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data.data);
          dispatch({ type: MEMBER.REMOVE_LIFF, data: data.data });
        } else return Promise.reject(data.error || "result error");
      })
      .catch((err) => {
        ERROR("removeLineLiff catch", err);
        if (typeof callback === "function") callback(err);
      });
  };
};

export const updateEmail = (email) => {
  return (dispatch) => {
    dispatch({
      type: MEMBER.UPDATE_EMAIL,
      data: email,
    });
  };
};

export const ResetUpdateProfileSuccess = () => {
  return (dispatch) => {
    dispatch({ type: MEMBER.SET_UPDATE_PROFILE_SUCCESS, data: null });
  };
};

export const updateLineDisplayName = (line_displayname) => {
  return (dispatch) => {
    dispatch({ type: MEMBER.UPDATE_LINE_DISPLAYNAME_LOADING, data: true });
    APIS.updateLineDisplayName({ line_displayname })
      .then(({ data }) => {
        dispatch({ type: MEMBER.UPDATE_LINE_DISPLAYNAME_LOADING, data: false });
      })
      .catch((err) => {
        dispatch({ type: MEMBER.UPDATE_LINE_DISPLAYNAME_LOADING, data: false });
      });
  };
};

export const updateNotificationSetting = (value) => {
  return (dispatch) => {
    APIS.updateNotificationSetting({ value })
      .then(({ data }) => {
        dispatch({ type: MEMBER.UPDATE_CFG_BLOCK_NOTI, data: value });
      })
      .catch((err) => {
        ERROR("updateNotificationSetting catch", err);
      });
  };
};
