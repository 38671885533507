import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore"
import { getStorage, ref } from 'firebase/storage'

const fbConfig = require('./../config').GlobalConfig.firebase
let firebaseApp

fbInit()
function fbInit() {
  if (!firebaseApp) {
    firebaseApp = initializeApp(fbConfig)
  }
}

export const fb = firebaseApp;
export const fbAuth = getAuth(firebaseApp)
export const fbFirestore = getFirestore(firebaseApp)
export const fbStorage = getStorage(firebaseApp)
export const imageRef = ref(fbStorage, 'images')
export const memberProfileRef = (memberBadgeCode) => ref(fbStorage, `${memberBadgeCode}/profile`);
export const memberBusinessPointRequestRef = (memberBadgeCode,business,fileName) => ref(fbStorage, `${memberBadgeCode}/businesses/${business}/pointRequest/${fileName}`);